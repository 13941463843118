<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <!-- <el-form-item label="操作人：">
              <el-input
                placeholder="请输入姓名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item> -->

            <el-form-item label="时间选择：" label-width="90px" style="width: 550px;">
              <div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time" type="date"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date" placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>

            <el-form-item label="分类：" label-width="60px">
              <el-select v-model="formLabelAlign.type" placeholder="请选择分类">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()">数据更新</i>
          </p>
          <div>
            <el-button @click="on_export">导出</el-button>
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="ID"></el-table-column>

            <el-table-column prop="address" label="名称">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_pop_addplus(scope.row)">{{ scope.row.instance_title
                }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="department_name" label="部门"></el-table-column>
            <el-table-column prop="task_code" label="类型"></el-table-column>
            <el-table-column prop="finish_type" label="完成类型"></el-table-column>
            <el-table-column prop="times_type" label="触发类型"></el-table-column>

            <el-table-column prop="scheme_name" label="模板名称"></el-table-column>

            <!-- <el-table-column prop="uid" label="操作人"></el-table-column> -->
            <el-table-column prop="status" label="是否完成"></el-table-column>

            <el-table-column prop="completed_time" label="完成时间"></el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog title="查看" show-close :visible.sync="dialog_show" center width="70%">
        <el-table :data="name_List" :header-cell-style="{ background: '#f8f8f9' }">
          <el-table-column v-for="(item, index) in dynamic_table" :key="index" :label="item.label">
            <template slot-scope="scope">
              <div v-if="item.type == 'text'">
                {{ scope.row[item.name] }}
              </div>
              <div v-else-if="item.type == 'upload_file'">
                <el-link v-if="scope.row[item.name]" style="font-size: 15px" type="primary" target="_blank">
                  下载文件
                </el-link>
              </div>
              <div v-else-if="item.type == 'upload_video'">
                <video v-if="scope.row[item.name]" style="width: 100%" controls="controls"
                  :src="scope.row[item.name]"></video>
              </div>
              <div v-else-if="item.type == 'upload_img'" class="img-sty">
                <el-image v-for="(el, ins) in $tools.img_split(scope.row[item.name])" :key="ins" class="el_image"
                  :src="el" :z-index="9999" :preview-src-list="[el]" />
              </div>

              <div v-else-if="item.type == 'group'">
                <div v-for="(el, ins) in item.items" :key="ins">
                  <p>{{ el.label }}:{{ scope.row[el.name] }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="paging">
          <el-pagination
            @current-change="way_namepage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="name_page_total"
            :current-page="name_page"
          >
          </el-pagination>
        </div> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";

import { worktaskindex, workinstancelog } from "../../assets/request/api";
import risklabelredact from "../../components/view_redact/risk_labelredact";
export default {
  name: "risk_label",
  mixins: [table_minin],
  components: { risklabelredact },
  data() {
    return {
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        // name: "",
        id: "",
        is_enable: "",
        type: "",
        begin_time: "",
        end_time: "",
      },
      dialog_show: false,
      title: "",
      //点击名称查看信息
      name_List: [],
      name_id: "",
      name_page_total: 0,
      name_page: 0,
      dynamic_table: [],
    };
  },
  created() {
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "check_times";
    this.url = workinstancelog;
    this.way_tableData();
  },
  methods: {
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    //点击名称查询
    on_pop_addplus(row) {
      if (row.code == "evaluation_recorder") {
        let { source_ids, id } = row;
        console.log(row);
        this.$router.push({
          path: "/assess/review_standingparticulars",
          query: {
            id: source_ids,
            log_id: id,
            type: "standing",
          },
        });
        return;
      }
      this.dialog_show = true;
      this.name_id = row.id;
      this.way_name();
    },
    //分页
    way_namepage(val) {
      this.name_page = val;
      this.way_name();
    },
    way_name() {
      let id = this.name_id;
      worktaskindex({ id }).then((res) => {
        let { data, page_total, header } = res.data;
        this.name_List = data;
        this.name_page_total = page_total;
        this.dynamic_table = header;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.upload-videosty {
  width: 160px;
  height: 160px;
  margin: 0 5px 5px 0;
  position: relative;
}

.imgs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.imgs>image {
  width: 23%;
  height: 150px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-bottom: 20px;

  /* border-radius:8px; */
}

.img-sty {
  display: flex;
  flex-wrap: wrap;

  .el_image {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 5px;
  }
}
</style>